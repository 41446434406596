<template>
  <video
      id="my-video"
      class="video-js vjs-default-skin"
      controls
      preload="auto"
      width="500px"
  >
    <source
        src="http://153.37.211.146:83/openUrl/pHtwcDK/playback.m3u8?beginTime=20210414T132300&endTime=20210414T132700"
        type="application/x-mpegURL"/>
  </video>
</template>

<script>
import 'video.js/dist/video-js.css'
import videojs from "video.js";
import "videojs-contrib-hls";

export default {
  name: "monitorPreview2",
  methods: {
    getVideo() {
      videojs(
          "my-video",
          {
            bigPlayButton: false,
            textTrackDisplay: false,
            posterImage: true,
            errorDisplay: false,
            controlBar: true
          },
          function () {
            this.play();
          }
      );
    }
  },
  mounted() {
    this.getVideo();
  },
}
</script>

<style scoped>

</style>
